var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.debugMode ? _c('span', {
    staticClass: "kiosk-info"
  }, [_c('div', [_vm.kiosk ? _c('span', [_vm._v("Name: " + _vm._s(_vm.kiosk.name) + " (ID: " + _vm._s(_vm.kiosk._id) + ")")]) : _vm._e(), _vm.kiosk.asset ? _c('span', [_vm._v(" | Asset: " + _vm._s(_vm.kiosk.asset.siteName) + " (ID: " + _vm._s(_vm.kiosk.assetId) + ")")]) : _vm._e()]), _c('div', [_vm.debug.lastRefresh ? _c('span', [_vm._v("Last refresh (kiosk): " + _vm._s(_vm.debug.lastRefresh) + " (" + _vm._s(_vm.debug.lastRefreshDuration) + "s)")]) : _vm._e(), _vm.debug.lastAssetRefresh ? _c('span', [_vm._v(" | Last refresh (asset): " + _vm._s(_vm.debug.lastAssetRefresh) + " (" + _vm._s(_vm.debug.lastAssetRefreshDuration) + "s)")]) : _vm._e()])]) : _vm._e(), !_vm.isOnline ? _c('span', {
    staticClass: "kiosk-error text-danger"
  }, [_c('span', {
    staticClass: "text-danger font-size-h1"
  }, [_vm._v("No internet connection. If this problem persists please contact Etainabl support.")])]) : _vm._e(), _vm.kiosk._id ? _c('div', {
    key: _vm.kiosk._id
  }, [_c('keep-alive', [_c(_vm.currentSlide.component, {
    tag: "component",
    attrs: {
      "data": _vm.currentSlide.data,
      "hide": _vm.hideSlide
    }
  })], 1)], 1) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }